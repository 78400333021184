import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import ActionPreview from '../../ActionPreview';
import { PreSelectedFormActionData } from './PreSelectedFormAction';
import { FormRendererType, useFormRendererInfo } from '../../../../contexts/FormRendererContext';
import { ActionPreviewBaseProps } from '../ActionBaseProps';
import { useCurrentClient } from '../../../../global-state/Clients';

const PreSelectedFormPreview: FC<ActionPreviewBaseProps<string, PreSelectedFormActionData>> = (props) => {
  const { response, data, answerOnly } = props;
  const { question, previewQuestion, previewDescription, newFormTitle } = data;
  const client = useCurrentClient((x) => x.value);
  const { type } = useFormRendererInfo();

  const title = useMemo(() => previewQuestion || question, [previewQuestion, question]);

  const answer = useMemo(() => {
    return type === FormRendererType.Platform ? (
      <Link
        to={`/clients/${client?.id}/forms/${response}/preview`}
        className="cursor-pointer underline"
        onClick={(e) => {
          e.stopPropagation();
        }}
        target="_blank"
        rel="noreferrer"
      >
        {newFormTitle}
      </Link>
    ) : (
      <span>{newFormTitle}</span>
    );
  }, [client?.id, newFormTitle, response, type]);

  if (answerOnly) {
    return <>{answer}</>;
  }

  return (
    <div>
      <ActionPreview data-cy="pre-selected-form-preview" question={title} description={previewDescription} answer={answer} />
    </div>
  );
};

export default PreSelectedFormPreview;
